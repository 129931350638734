@import "../css/variables.module";
.excalidraw-tooltip {
  position: absolute;
  z-index: 1000;

  padding: 8px;
  border-radius: 6px;
  box-sizing: border-box;
  pointer-events: none;
  word-wrap: break-word;

  background: $oc-black;

  line-height: 1.5;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: $oc-white;

  display: none;

  &.excalidraw-tooltip--visible {
    display: block;
  }
}

.excalidraw {
  .Tooltip-icon {
    width: 0.9em;
    height: 0.9em;
    margin-left: 5px;
    margin-top: 1px;
    display: flex;

    @include isMobile {
      display: none;
    }
  }
}
